import React from 'react'
import CountUp from 'react-countup';

const Values = () => {
    const data = [
        {
            id: 1,
            value: 2100,
            sub: "+",
            description: "Critical exploits detected"
        },
        {
            id: 1,
            value: 126,
            sub: "M+",
            description: "We secured on protocols"
        },
        {
            id: 1,
            value: 3,
            sub: "B+",
            description: "Total value monitored"
        }
    ]
    return (
        <div className='values py-[60px] bg-[#181818]'>
            <div className="container">
                <div className="text-center">
                    <div className="inline-flex mx-auto justify-around items-center text-center lg:flex-row flex-col gap-[50px] md:gap-[100px]">
                        {
                            data.map((item, index) => (
                                <div key={item.id} className={`flex ${index !== 0 && "md:border-l md:border-[#282829]"} md:pl-[100px] justify-center text-center items-center`}>
                                    <div className="left">
                                        <h2 className='font-semibold text-white text-[32px] md:text-[50px]'>
                                            <CountUp enableScrollSpy={true} end={item.value} />{item.sub}
                                        </h2>
                                        <p>
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Values