import React from "react";

const Differential = () => {
  return (
    <div className=" py-[40px]">
      <div className="container">
        <div className="differential grid items-center has-bg lg:grid-cols-2 grid-cols-1 gap-[23px] md:gap-[100px]">
          <div className="left bg-d md:text-left text-center">
            <h2 className="text-white md:text-left text-center text-[32px] md:text-[42px] mb-5">
              Our Differentiation
            </h2>
            <p className="text-sm md:text-xl mb-8">
              <b>1. Data-Driven Methodology: </b> <br />
              PRISMA utilizes a comprehensive data-driven approach, analyzing
              extensive blockchain transaction datasets for accurate anomaly
              detection.
              <br />
              <br />
              <b>2. ADASYN for Imbalanced Datasets:</b> <br /> To combat
              imbalanced datasets, PRISMA employs ADASYN, ensuring equitable
              fraud detection across data variances.
              <br />
              <br />
              <b>3. Tree-Based Ensemble Models:</b> <br /> The platform
              harnesses tree-based ensemble models for their superior handling
              of transaction complexities and fraud identification.
              <br />
              <br />
              <b>4. Explainable AI Focus:</b> <br /> PRISMA prioritizes
              explainable AI, fostering transparency and trust in its anomaly
              detection processes among stakeholders.
            </p>
          </div>

          <div className="right md:text-left text-center">
            <p className="text-sm md:text-xl text-white mb-8">
              Additional Innovations (Classified): While we have outlined key
              aspects of our approach, it’s important to note that PRISMA
              encompasses a broader spectrum of technologies and methodologies.
              Due to classification and confidentiality, further details on
              these advanced components cannot be disclosed at this time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Differential;
